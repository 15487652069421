import { RadioButtonGroup, TextField } from '@components'
import TextFieldPassword from '@components/TextFieldPassword'
import useForm, { ValidationRule } from '@utils/hooks/useForm'
import { TCreateCredentialProps, IUpdateCredentialProps } from '@utils/api'
import React, { ReactElement } from 'react'
import { Stack } from '@mui/material'

export type TFormAddCredentials = TCreateCredentialProps
export type TFormEditCredentials = IUpdateCredentialProps
interface Props {
  form: ReturnType<typeof useForm>
  oneTime?: boolean
  edit?: boolean
}

export const initialValues: TFormAddCredentials = {
  // credentials_id: '',
  credentials_name: '',
  username: '',
  password: '',
  credentials_type: 'ssh_key',
  ssh_key_b64: '',
  ssh_key_certificate_b64: '',
  ssh_key_password: ''
}

export const initialValidation: ValidationRule<TFormAddCredentials> = {
  // credentials_id: (value) => value.length > 0 && /^([a-z0-9_]*)\S$/.test(value),
  credentials_name: (value) =>
    value.length > 0 && /^[ a-zA-Z0-9_\-]{1,255}$/.test(value),
  username: (value) => value.length > 0,
  credentials_type: (value) => value === 'ssh_key' || value === 'username',
  ssh_key_b64: (value, values) =>
    values.credentials_type !== 'ssh_key' || value.length > 0,
  password: (value, values) =>
    values.credentials_type !== 'username' ||
    (value !== undefined && value.length > 0)
}

export function FormAddCredentials({
  form,
  oneTime = false,
  edit = false
}: Props): ReactElement {
  const { credentials_type } = form.values
  return (
    <Stack spacing={3}>
      {!oneTime && (
        <TextField
          required
          id="credentials_name"
          label="Name"
          form={form}
          disabled={edit}
          inputProps={edit ? { readOnly: true } : {}}
          helperText="Must be unique, no longer then 255 characters and only contain numbers, letters, spaces, hyphens and underscores."
        />
      )}

      <TextField
        required
        id="username"
        label="Username"
        variant="outlined"
        form={form}
      />

      <RadioButtonGroup
        label="Authentication type"
        name="credentials_type"
        options={[
          {
            value: 'ssh_key',
            label: 'SSH Private Key'
          },
          {
            value: 'username',
            label: 'Username/Password'
          }
        ]}
        form={form}
      />

      {credentials_type === 'ssh_key' ? (
        <>
          <TextField
            required
            id="ssh_key_b64"
            label="Private Key"
            placeholder="SSH private key"
            multiline
            rows={4}
            form={form}
          />
          <TextFieldPassword
            form={form}
            id="ssh_key_password"
            label="SSH Password"
            variant="outlined"
          />
          <TextField
            id="ssh_key_certificate_b64"
            label="Key Certificate"
            multiline
            rows={4}
            form={form}
          />
          <TextFieldPassword
            form={form}
            id="password"
            label="Sudo Password"
            variant="outlined"
          />
        </>
      ) : (
        <>
          <TextFieldPassword
            form={form}
            id="password"
            label="Password"
            required
          />
        </>
      )}
    </Stack>
  )
}

export function validateAddCredentials(form: ReturnType<typeof useForm>) {
  return form.validateFields([
    'name',
    'username',
    'credentials_type',
    'ssh_key_b64',
    'password'
  ])
}
