import { RadioButtonGroup, TextField, Select, TagField } from '@components'
import TextFieldAutocomplete from '@components/TextFieldAutocomplete'
import useApi from '@utils/hooks/useApi'
import useFeatures from '@utils/hooks/useFeatures'
import useForm, { ValidationRule } from '@utils/hooks/useForm'
import { Skeleton, Stack } from '@mui/material'
import { TaskQueueNameResponse } from '@utils/api'
import { getCredentials } from '@utils/api/credentials'
import { getJumpHosts, JumpHostsResponse } from '@utils/api/jumphosts'
import { getQueueNames } from '@utils/api/tasks'
import { arrayFromCommaString } from '@utils/misc'
import React, { ReactElement, useEffect, useState } from 'react'
import {
  FormAddCredentials,
  TFormAddCredentials,
  initialValues as addCredentialsInitialValues,
  initialValidation as addCredentialsInitialValidation,
  validateAddCredentials
} from './FormAddCredentials'

export type TFormAddHost = TFormAddCredentials & {
  ip_type: 'ip_list' | 'ip_range'
  ip_list: string[]
  ip_range: string[]
  ssh_port: number | ''
  tags: string[]
  credentials_create: 'new' | 'existing'
  credentials_id: string
  jump_hosts: string[]
  queue_name: string
}

interface Props {
  form: ReturnType<typeof useForm>
}

export const initialValues: TFormAddHost = {
  ...addCredentialsInitialValues,
  ip_type: 'ip_list',
  ip_list: [],
  ip_range: [],
  ssh_port: 22,
  tags: [],
  credentials_create: 'existing',
  credentials_id: '',
  credentials_name: '',
  jump_hosts: [],
  queue_name: ''
}

export const initialValidation: ValidationRule<TFormAddHost> = {
  ip_list: (value, values) => values.ip_type !== 'ip_list' || value.length > 0,
  ip_range: (value, values) =>
    values.ip_type !== 'ip_range' || value.length > 0,
  ssh_port: (value) => value === '' || (value > 0 && value < 65536),
  credentials_create: (value) => value === 'new' || value === 'existing',
  credentials_id: (value, values) =>
    values.credentials_create !== 'existing' || value.length > 0,
  ...addCredentialsInitialValidation,
  queue_name: (value) => value.length > 0
}

export function FormAddHost({ form }: Props): ReactElement {
  const { canUseFeature } = useFeatures()
  const canTag = canUseFeature('host_tags')

  const {
    values: { ip_type }
  } = form

  return (
    <Stack spacing={3}>
      <RadioButtonGroup
        label="Type"
        name="ip_type"
        options={[
          {
            value: 'ip_list',
            label: 'IP / Hostname List'
          },
          {
            value: 'ip_network',
            label: 'IP Range'
          }
        ]}
        form={form}
      />
      {ip_type === 'ip_list' && (
        <TextField
          id="ip_list"
          label="IP / Hostname List"
          required={ip_type === 'ip_list'}
          helperText={
            'One IP address per line. An array of IP addresses or hostnames to scan.'
          }
          multiline
          rows={4}
          form={form}
          value={form.values.ip_list.join('\n')}
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) =>
            form.setFieldValue(
              'ip_list',
              arrayFromCommaString(event.currentTarget.value)
            )
          }
        />
      )}
      {ip_type === 'ip_network' && (
        <TextField
          id="ip_range"
          label="IP Ranges"
          required={ip_type === 'ip_range'}
          helperText={'One IP range per line. A class C with subnet mask.'}
          multiline
          rows={4}
          form={form}
          value={form.values.ip_range.join('\n')}
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) =>
            form.setFieldValue(
              'ip_range',
              arrayFromCommaString(event.currentTarget.value)
            )
          }
        />
      )}

      <TextField
        type="number"
        id="ssh_port"
        label="SSH Port"
        required
        form={form}
        helperText="SSH port to use for authentication. Port values must only be between 1-65535"
      />

      {canTag && (
        <TagField
          id="tags"
          label="Tags"
          form={form}
          helperText="Search and select one or more existing tags, or enter a new tag and press return key to add."
        />
      )}
    </Stack>
  )
}

export function validateFormAddHost(form: ReturnType<typeof useForm>) {
  return form.validateFields(['ip_list', 'ip_network', 'ssh_port', 'tags'])
}

export function FormAddHostCredentials({ form }: Props): ReactElement {
  const { loading, response } = useApi({
    // TODO handle errors
    apiMethod: getCredentials
  })

  const [credentialOptions, setCredentialOptions] = useState<
    { value: string; label: string }[]
  >([])

  useEffect(() => {
    if (response && response.data && Array.isArray(response.data)) {
      setCredentialOptions(
        response.data.map(({ id }) => ({
          value: id,
          label: id
        }))
      )
    }
  }, [response])

  useEffect(() => {
    if (credentialOptions.length > 0) {
      form.setFieldValue('credentials_id', credentialOptions[0].value)
    }
  }, [credentialOptions])

  return (
    <Stack spacing={3}>
      <RadioButtonGroup
        label="Credential Type"
        name="credentials_create"
        options={[
          {
            value: 'new',
            label: 'New'
          },
          {
            value: 'existing',
            label: 'Existing'
          }
        ]}
        form={form}
      />
      {form.values.credentials_create === 'new' && (
        <FormAddCredentials form={form} />
      )}

      {form.values.credentials_create === 'existing' && (
        <>
          {!loading && response ? (
            <Select
              id="credentials_id"
              label="Credential"
              form={form}
              options={credentialOptions}
              placeholder="Select credentials..."
            />
          ) : (
            <Skeleton variant="rectangular" width={'100%'} height={56} />
          )}
        </>
      )}
    </Stack>
  )
}

export function validateAddHostCredentials(form: ReturnType<typeof useForm>) {
  return form.values.credentials_create === 'existing'
    ? form.validateFields(['credentials_id'])
    : validateAddCredentials(form)
}

export function FormAddHostJumpHost({ form }: Props): ReactElement {
  const { loading, response } = useApi<JumpHostsResponse>({
    // TODO handle errors
    apiMethod: getJumpHosts
  })

  const [jumpHostOptions, setJumpHostOptions] = useState<string[]>([])

  useEffect(() => {
    if (response && response.data && Array.isArray(response.data)) {
      const jumpHosts = response.data.map(({ name }) => name)
      setJumpHostOptions(jumpHosts)
    }
  }, [response])

  return (
    <Stack spacing={3}>
      {!loading && response ? (
        <TextFieldAutocomplete
          required={false}
          id="jump_hosts"
          label="Jump Host"
          form={form as any}
          options={jumpHostOptions}
          helperText="Optional. Select in order of connection."
          freeSolo={false}
        />
      ) : (
        <Skeleton variant="rectangular" width={'100%'} height={56} />
      )}
    </Stack>
  )
}

export function FormAddHostQueue({ form }: Props): ReactElement {
  const { loading, response } = useApi<TaskQueueNameResponse>({
    // TODO handle errors
    apiMethod: getQueueNames
  })

  const [queueOptions, setQueueOptions] = useState<
    { value: string; label: string }[]
  >([])

  useEffect(() => {
    if (response && response.queues && Array.isArray(response.queues)) {
      setQueueOptions(
        response.queues.map(
          ({ name, total }: { name: string; total: number }) => ({
            value: name,
            label: `${name} (${total})`
          })
        )
      )
    }
  }, [response])

  useEffect(() => {
    if (queueOptions.length > 0) {
      form.setFieldValue('queue_name', queueOptions[0].value)
    }
  }, [queueOptions])

  return (
    <>
      {!loading && response ? (
        <Select
          id="queue_name"
          label="Queue"
          form={form}
          options={queueOptions}
          helperText="Scanning node queue name to use to add hosts."
          placeholder="Select queue..."
        />
      ) : (
        <Skeleton variant="rectangular" width={'100%'} height={56} />
      )}
    </>
  )
}
