import React from 'react'
import useForm, { TUseForm } from '@hooks/useForm'
import {
  FormAddHost,
  initialValues,
  initialValidation,
  FormAddHostCredentials,
  FormAddHostQueue,
  validateFormAddHost,
  validateAddHostCredentials,
  TFormAddHost,
  FormAddHostJumpHost
} from '../../components/Form/FormAddHost'
import { createHosts } from '@utils/api'
import SteppedFormPage from '@templates/Generic/SteppedForm'
import useApi from '@hooks/useApi'
import useFeatures from '@hooks/useFeatures'

function isStepValid(step: number, form: any) {
  switch (step) {
    case 0:
      return validateFormAddHost(form)
    case 1:
      return validateAddHostCredentials(form)
    default:
      return true
  }
}

const HostsAddPage = () => {
  const form = useForm<TFormAddHost>({
    initialValues,
    initialValidationRules: initialValidation
  })

  const { canUseFeature } = useFeatures()
  const canAddJumpHosts = canUseFeature('jump_hosts')

  const api = useApi({ apiMethod: createHosts, requestOnMount: false })

  return (
    <SteppedFormPage
      api={api}
      isStepValid={isStepValid}
      getStepContent={(step: number, form: any) => {
        switch (step) {
          case 0:
            return <FormAddHost form={form} />
          case 1:
            return <FormAddHostCredentials form={form} />
          case 2:
            return canAddJumpHosts ? (
              <FormAddHostJumpHost form={form} />
            ) : (
              <FormAddHostQueue form={form} />
            )
          case 3:
            return canAddJumpHosts ? (
              <FormAddHostQueue form={form} />
            ) : (
              <>{'Unknown step'}</>
            )

          default:
            return <>{'Unknown step'}</>
        }
      }}
      title="Add Host"
      steps={
        canAddJumpHosts
          ? ['Host Details', 'Add Credentials', 'Add Jump Host', 'Select Queue']
          : ['Host Details', 'Add Credentials', 'Select Queue']
      }
      successMessage="Host Successfully accepted for processing."
      breadcrumbs={[
        {
          title: 'Hosts',
          to: '/hosts/'
        },
        {
          title: 'Add Host'
        }
      ]}
      form={form as TUseForm<any>}
    />
  )
}

export default HostsAddPage
